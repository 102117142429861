import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { StatusLabel, Notification } from 'hds-react';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
import ExternalLink from '../../../components/ExternalLink';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "basics",
      "style": {
        "position": "relative"
      }
    }}>{`Basics`}<a parentName="h1" {...{
        "href": "#basics",
        "aria-label": "basics permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <StatusLabel type="alert" mdxType="StatusLabel">WIP</StatusLabel>
    <LeadParagraph mdxType="LeadParagraph">
  The cookie compliance pattern describes how the user is informed about the use of cookies. The pattern also offers a
  way to accept or decline non-necessary cookies and change the consent later if needed.
    </LeadParagraph>
    <p>{`The city of Helsinki cookie pattern is based on `}<ExternalLink size="M" href="https://www.traficom.fi/en/regulations/guidance-use-web-cookies-service-providers" mdxType="ExternalLink">{`Traficom's cookie guidelines for service provider`}</ExternalLink>{`.`}</p>
    <h2 {...{
      "id": "cookie-basics",
      "style": {
        "position": "relative"
      }
    }}>{`Cookie basics`}<a parentName="h2" {...{
        "href": "#cookie-basics",
        "aria-label": "cookie basics permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "what-are-cookies",
      "style": {
        "position": "relative"
      }
    }}>{`What are cookies?`}<a parentName="h3" {...{
        "href": "#what-are-cookies",
        "aria-label": "what are cookies permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Cookies are pieces of data that are stored on the user's device. They are a way to store persistent information about how the user is using the service. The use of cookies is controlled by law. The law requires that cookies that are not necessary for the service must be asked for consent from the user.`}</p>
    <h3 {...{
      "id": "when-the-consent-for-cookies-should-be-asked",
      "style": {
        "position": "relative"
      }
    }}>{`When the consent for cookies should be asked?`}<a parentName="h3" {...{
        "href": "#when-the-consent-for-cookies-should-be-asked",
        "aria-label": "when the consent for cookies should be asked permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The consent needs to be asked for a cookie that is not necessary for the use of the service. This means that the service's main functions are available even without these cookies. In the future, we refer to these cookies as `}<strong parentName="p">{`optional cookies`}</strong>{`.`}</p>
    <p>{`If the service cannot be used or one of its main functions does not operate without a specific cookie, the cookie can be considered `}<strong parentName="p">{`necessary`}</strong>{`. Necessary cookies do not need consent from the user but they still have to be informed. The user cannot deny the use of necessary cookies - these selections are grayed out in the cookie consent component. The table below demonstrates when consent is needed and when it is not.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Types of cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Is consent needed?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`What needs to be done?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Only necessary cookies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The cookie consent banner does not need to be shown. The list of cookies has to be available on a separate cookies page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Only optional cookies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show the cookie consent banner and ask for consent for each optional cookie category. The list of cookies has to be available on a separate cookies page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Both necessary and optional cookies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes, for all optional cookies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show the cookie consent banner and ask for consent for each optional cookie category. The list of cookies has to be available on a separate cookies page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`No cookies at all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No actions needed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Cases when the consent is needed and when it is not]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`Refer to the next chapter to learn how to categorise your cookies and decide which cookies should be necessary and which should not.`}</p>
    <h2 {...{
      "id": "cookie-information-and-categorisation",
      "style": {
        "position": "relative"
      }
    }}>{`Cookie information and categorisation`}<a parentName="h2" {...{
        "href": "#cookie-information-and-categorisation",
        "aria-label": "cookie information and categorisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`The cookie consent needs to be asked when your service becomes public (even during alpha/beta periods).`}</p>
    <p>{`To prepare to use the HDS cookie compliance component, you have to:`}</p>
    <ol>
      <li parentName="ol">{`List all the cookies related to your service`}</li>
      <li parentName="ol">{`Decide which cookies are necessary`}</li>
      <li parentName="ol">{`Categorise cookies into predefined categories`}</li>
    </ol>
    <p>{`We will go over each step in the following chapters.`}</p>
    <h3 {...{
      "id": "1-list-all-the-cookies-related-to-your-service",
      "style": {
        "position": "relative"
      }
    }}>{`1. List all the cookies related to your service`}<a parentName="h3" {...{
        "href": "#1-list-all-the-cookies-related-to-your-service",
        "aria-label": "1 list all the cookies related to your service permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`First, you need to list all the cookies your service is using. It is also a good moment to review the list of cookies. Aim to use as few cookies as possible and only gather the information your service `}<strong parentName="p">{`currently`}</strong>{` needs. You should easily be able to justify the need for each cookie to the user.`}</p>
    <p>{`For each cookie, you need to gather and display the following information. This information will be displayed in the cookie consent window if the user wishes to get more information about a specific cookie.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie data`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Name`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`What is the name of the cookie file?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"cookie-agreed"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Cookie set by`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`What domain is the provider of this cookie?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"hel.fi"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Purpose of use`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Why the cookie is needed? What purpose does it serve?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Used to store information about whether visitors have given or declined the use of cookie categories used on this service."`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Period of validity`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`How long the cookie will be valid? This means time to expire from the creation of the cookie.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"100 days"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Information that needs to be shown for each cookie]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "what-if-the-user-has-given-consent-to-my-cookies-in-some-other-helsinki-service",
      "style": {
        "position": "relative"
      }
    }}>{`What if the user has given consent to my cookies in some other Helsinki service?`}<a parentName="h4" {...{
        "href": "#what-if-the-user-has-given-consent-to-my-cookies-in-some-other-helsinki-service",
        "aria-label": "what if the user has given consent to my cookies in some other helsinki service permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Using common Helsinki cookie consent brings the benefit of sharing consents. If user gives consents at site A, same consents does not have to be asked again at site B. Sites sharing cookies must have the same domain. e.g. `}{`*`}{`.hel.fi. To learn more about common Helsinki service cookies, please refer to the `}<InternalLink href="/patterns/cookies/common-helsinki-cookies" mdxType="InternalLink">{`Common Helsinki cookies page.`}</InternalLink></p>
    <h3 {...{
      "id": "2-decide-which-cookies-are-necessary",
      "style": {
        "position": "relative"
      }
    }}>{`2. Decide which cookies are necessary`}<a parentName="h3" {...{
        "href": "#2-decide-which-cookies-are-necessary",
        "aria-label": "2 decide which cookies are necessary permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The next step is to determine which cookies can be considered necessary. Generally, you will need to ask consent for all cookies that are not vital to your service to operate. If you feel that the user can utilise the main functions of your service without a specific cookie, the cookie cannot be considered necessary.`}</p>
    <p>{`If you have a hard time determining whether a cookie is a necessary cookie, refer to the cookie category table in the next chapter for more examples.`}</p>
    <Notification label="A rule of thumb about necessary cookies" className="siteNotification" mdxType="Notification">
  As a general rule of thumb, if even one use case of the cookie is not necessary, then the cookie must be optional and
  consent is needed. An example of this is Google's reCAPTHA. While the primary use of the cookie is for information
  security and it could be considered necessary, Google will also gather data about the user. Due to this, the cookie
  cannot be considered necessary and consent is needed.
    </Notification>
    <h3 {...{
      "id": "3-categorise-cookies-into-predefined-categories",
      "style": {
        "position": "relative"
      }
    }}>{`3. Categorise cookies into predefined categories`}<a parentName="h3" {...{
        "href": "#3-categorise-cookies-into-predefined-categories",
        "aria-label": "3 categorise cookies into predefined categories permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`To help the user understand the reasoning behind cookies, it is required to group cookies into pre-determined categories. These categories are based on `}<ExternalLink size="M" href="https://www.traficom.fi/en/regulations/guidance-use-web-cookies-service-providers" openInNewTab openInNewTabAriaLabel="Opens in a new tab" openInExternalDomainAriaLabel="Opens a different website" mdxType="ExternalLink">{`Traficom's cookie guidelines for service provider`}</ExternalLink>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Is necessary?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Login`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Login cookies are used when the user logs in to the service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session-specific cookies are considered necessary. Permanent login cookies always need consent.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session cookies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Preferences`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Preference cookies customise the layout and operation of the website based on the choices made by the user.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Preferences that improve usability between service uses (e.g. language) can be considered essential. As session-based these can be considered necessary. Preferences valid longer than one session can be considered necessary if they are required to ensure usability.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Language choices, appearance settings, accessibility preferences, cookie consent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`User input`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User-input cookies can be used to store information entered by the user in the service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If using the service would not be possible without input-related cookies, they can be considered necessary.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Search history, last used search filters, shopping cart contents, saved form inputs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Advertising and marketing`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Marketing cookies can be used to target content to users of the website.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Never necessary.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User’s interests, service usage history`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Information security`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Security cookies enable secure data transfer between the user and the service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually necessary unless the cookie has other use cases that are not related to security.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CAPTCHA results`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Social media`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Social media cookies are related to the characteristics of various social media services.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Never necessary.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Social media feeds, social media share buttons`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Accessibility`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accessibility cookies enable some of the service’s accessibility features.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Always necessary.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accessibility related preferences such as audio descriptions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Statistics`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The information collected by statistics cookies is used to develop the website.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually not necessary. If analytics are considered absolutely necessary for the service, cookies can be considered essential and this must be informed to the user. When considered essential, this must be justified to the user. Gathered information must not be handed to third parties.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitor IP addresses, page views count, measurements of how the user uses the service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Device information`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information cookies contain information collected from the user’s device that ensures the functionality of the service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Never necessary.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information, OS, and browser information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Chat services`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat service cookies enable communication between the user and the service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can only be considered necessary if real-time communication is the main purpose of the service. When the user opens the chat window, cookies related to it become necessary. Note that they are not necessary until the user opens the chat.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat provider cookies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Third-party services`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookies allow external services (such as social media platforms) to operate on this website.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not considered necessary unless cookies are used only after opening the third-party service (e.g. a chat window).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookies used by embedded media such as videos or podcasts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Content presentation`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookies are used to display various types of content to the user.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can be considered necessary if the cookie is strictly related to presenting the content and not gathering data.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Monitoring how the user is using specific content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Location`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location cookies can be used to utilise location information (GPS) received from the user’s device.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Never necessary.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accurate GPS location`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Load balancing`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Load-balancing cookies ensure that the service loads and works quickly and efficiently.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Always necessary (as they ensure that the service is operating smoothly).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A client identifying hash to enable better routing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Cookie categories]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "can-i-use-a-custom-category",
      "style": {
        "position": "relative"
      }
    }}>{`Can I use a custom category?`}<a parentName="h4" {...{
        "href": "#can-i-use-a-custom-category",
        "aria-label": "can i use a custom category permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`If your cookies do not fit into any of the pre-defined categories, you are allowed to use custom categories.`}</p>
    <h4 {...{
      "id": "we-only-have-a-handful-of-cookies-do-we-still-have-to-categorize-them",
      "style": {
        "position": "relative"
      }
    }}>{`We only have a handful of cookies. Do we still have to categorize them?`}<a parentName="h4" {...{
        "href": "#we-only-have-a-handful-of-cookies-do-we-still-have-to-categorize-them",
        "aria-label": "we only have a handful of cookies do we still have to categorize them permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Yes, cookies always have to be put into a category. If you have a small number of cookies you can consider grouping them into a single category. Also, if most of the cookies are related to a single third-party service, you can group them per service (E.g. "Service: YouTube").`}</p>
    <h3 {...{
      "id": "prepare-your-cookieconsent-cookiemodalcookiepage-component",
      "style": {
        "position": "relative"
      }
    }}>{`Prepare your CookieConsent (CookieModal/CookiePage) component`}<a parentName="h3" {...{
        "href": "#prepare-your-cookieconsent-cookiemodalcookiepage-component",
        "aria-label": "prepare your cookieconsent cookiemodalcookiepage component permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Now that you have gathered and categorised a list of cookies for your service, it is a time to learn how the HDS CookieConsent is used. To read more about the component, please refer to the `}<InternalLink href="/patterns/cookies/using-the-cookieconsent" mdxType="InternalLink">{`Using the CookieConsent page.`}</InternalLink></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      